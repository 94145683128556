import React, {useEffect, useState} from 'react';

import {DataGrid, GridColDef} from '@mui/x-data-grid';

const columns: GridColDef[] = [
    {field: 'id', headerName: 'Id', width: 80},
    {field: 'name', headerName: 'Name', width: 150},
    {field: 'lastName', headerName: 'Last Name', width: 150},
    {field: 'email', headerName: 'Email', width: 150},
    {field: 'address', headerName: 'Address', width: 150},
    {field: 'dateOfBirth', headerName: 'DOB', width: 150},
    {field: 'login', headerName: 'Login', width: 150},
    {field: 'phone', headerName: 'Phone', width: 150},
    {field: 'role', headerName: 'Role', width: 150},
    {field: 'sexType', headerName: 'Sex', width: 150},
    {field: 'club', headerName: 'Club', width: 150},
    {field: 'participantType', headerName: 'Participant Type', width: 150},
];

type User = {
    id: string;
    name: string;
    lastName: string;
    email: string;
    address: string;
    dateOfBirth: string;
    login: string;
    phone: string;
    role: { name: string };
    sexType: string;
    club: string;
    participantType: string;
}


export default function Users() {
    let isLoading = false;
    let rowCountState = 8000;


    let arr: User[] = [];
    const [posts, setPosts] = useState(arr);
    useEffect(() => {
        fetch('http://localhost:8080/allUsers')
            .then(response => response.json())
            .then(data => {
                rowCountState = data.totalElements;
                setPosts(data.content)
            })

    }, []);


    return (
        <>
            <div>Users list</div>
            <div style={{height: 500, width: '60%', color: 'white'}}>

                <DataGrid rows={posts} columns={columns}
                          loading={isLoading}
                          sx={{
                              boxShadow: 2,
                              border: 2,
                              borderColor: 'primary.light',
                              '& .MuiDataGrid-cell:hover': {
                                  color: 'primary.light',
                              },
                              color: 'white',
                          }}
                />
            </div>
        </>)

}
