import React from 'react';
import logo from './logo.svg';
import './App.css';
import Users from "./Users";


function App() {

    return (
        <div className="App">
            <div className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <Users/>
            </div>
        </div>
    );
}

export default App;
